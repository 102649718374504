<template>
  <div>
    <v-text-field
      v-model="textModel"
      :label="label"
      placeholder="RUT"
      :rules="rules"
      :prepend-inner-icon="prependIcon"
      :background-color="readonly === true ? '#F2F2F2' : 'none'"
      :disabled="readonly === true ? true : false"
      :readonly="readonly"
      :error-messages="error"
      dense
      outlined
      v-mask="rutMask"
      max_length="12"
      :return-masked-value="returnMaskedValue"
      @input="$emit('input', $event)"
    ></v-text-field>
  </div>
</template>

<script>
import { rutMask } from "@/helpers/mask.js";
export default {
  name: "txt",
  props: {
    rules: {
      type: Array,
      default: () => [],
    },
    text: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
    prependIcon: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    returnMaskedValue: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      textModel: this.text,
    };
  },
  computed: {
    rutMask: () => rutMask,

    /* inputVal: {
      get() {
        if (this.model !== "") {
          return this.format(this.model);
        } else return this.model;
      },
      set(val) {
        if (this.readonly === "false") {
          if (val) {
            this.$emit("input", this.format(val));
          } else {
            this.$emit("input", "val");
          }
        }
      },
    }, */
  },
  methods: {
    clean(rut) {
      return typeof rut === "string"
        ? rut.replace(/^0+|[^0-9kK]+/g, "").toUpperCase()
        : "";
    },

    format(rut) {
      rut = this.clean(rut);

      let result = rut.slice(-4, -1) + "-" + rut.substr(rut.length - 1);
      for (let i = 4; i < rut.length; i += 3) {
        result = rut.slice(-3 - i, -i) + "." + result;
      }

      return result;
    },
  },
};
</script>

<style scoped>
@import "../../assets/CSS/TextField.css";

.clase {
  flex-grow: 1;
}
</style>
