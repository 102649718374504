import axios from "axios";
export const Register = {
  data: () => ({
    loadingChannels: false,
    loadingSucursals: false,
  }),
  methods: {
    Show() {
      if (this.Password.type === "password") {
        this.Password.type = "text";
        this.ConfirmPassword.type = "text";
      } else {
        this.Password.type = "password";
        this.ConfirmPassword.type = "password";
      }
    },

    setSucursals() {
      this.loadingSucursals = true;
      this.Text_Sucursal.selected = "";
      this.getSucursal().finally(() => {
        this.loadingSucursals = false;
      });
    },

    getSucursal() {
      this.Text_Sucursal.answers = [];
      this.Text_Sucursal.disabled = false;
      return new Promise((resolve, reject) => {
        axios({
          url: `convenios/${this.$store.getters.url}/canales/${this.Text_Canal.selected}/sucursales`,
          method: "GET",
        })
          .then((response) => {
            this.disabled = false;

            const res = response.data.data.Sucursales.sort((a, b) =>
              a.sucursal_descripcion.localeCompare(b.sucursal_descripcion)
            );
            for (const element of res) {
              this.Text_Sucursal.answers.push({
                label: element.sucursal_descripcion,
                value: element.sucursal,
              });
            }

            resolve(res);
          })
          .catch(() => {
            reject(
              new Error(
                "Ha ocurrido un error al intentar recuperar las sucursales"
              )
            );
          });
      });
    },

    closeDialog() {
      if (this.$store.getters.isLoggedIn) {
        this.$store.dispatch("closeDialog");
        this.$router.push("/personas");
      } else {
        this.$store.dispatch("closeDialog");
        this.$router.push({ name: "Login" });
      }
    },

    Return() {
      this.$router.go(-1);
    },
  },
  computed: {
    loadingstatus() {
      return this.$store.getters.loadingstatus;
    },
    usermodal() {
      return this.$store.getters.usermodal;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    isPosted() {
      return this.$store.getters.isPosted;
    },
  },
  async mounted() {
    this.loadingChannels = true;
    await axios({
      url: `convenios/listar`,
      method: "GET",
    })
      .then((response) => {
        const res = response.data.data.lista_canal.sort((a, b) =>
          a.canal_descripcion.localeCompare(b.canal_descripcion)
        );
        for (const element of res) {
          this.Text_Canal.answers.push({
            label: element.canal_descripcion,
            value: element.canal,
          });

          this.Text_Canal.id = element.canal;
        }
      })
      .catch(() => {})
      .finally(() => {
        this.loadingChannels = false;
      });
  },
};
