<template name="AlertComponent">
  <v-alert
    dense
    :type="variant"
    border="left"
    colored-border
    :class="alertClass"
  >
    <template slot="prepend">
      <v-icon>{{ `mdi-${icon}` }}</v-icon>
    </template>
    <slot name="content"></slot>
  </v-alert>
</template>
<script>
export default {
  name: "AlertComponent",
  props: {
    variant: String,
  },
  computed: {
    icon() {
      let icon = "";
      switch (this.variant) {
        case "warning":
          icon = "alert";
          break;
        case "error":
          icon = "alert-circle";
          break;
        case "info":
          icon = "information";
          break;
        case "success":
          icon = "check-circle";
          break;
      }

      return icon;
    },
    iconClass() {
      let iconClass = "";
      switch (this.variant) {
        case "warning":
          iconClass = "warning-text mr-2";
          break;
        case "error":
          iconClass = "error-text mr-2";
          break;
        case "info":
          iconClass = "info-text mr-2";
          break;
        case "success":
          iconClass = "success-text mr-2";
          break;
      }

      return iconClass;
    },
    alertClass() {
      let alertClass = "";
      switch (this.variant) {
        case "warning":
          alertClass = "warning-alert";
          break;
        case "error":
          alertClass = "error-alert";
          break;
        case "info":
          alertClass = "info-alert";
          break;
        case "success":
          alertClass = "success-alert";
          break;
      }

      return alertClass;
    },
  },
  methods: {},
};
</script>
<style scoped>
.material-symbols-rounded {
  color: v-bind("color") !important;
  font-size: v-bind("size");
}
</style>
