<template>
  <div class="text-center">
    <v-dialog v-model="open" width="600px" persistent attach="#main-container">
      <v-card class="py-5">
        <v-card-text>
          <div class="d-flex justify-space-between align-center">
            <span class="bold h5">Aprobar usuario</span>
            <v-btn v-on:click.native="close" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div class="d-flex mt-4 justify-space-between align-center">
            <span>
              Para continuar con la autorización de
              <span class="bold text-capitalize">
                {{ userdata.nombre_largo }}, Rut
                {{ userdata.usuario | rutFormat }} </span
              >, confirme canal y sucursal
            </span>
          </div>

          <v-form ref="authorize">
            <div class="d-flex mt-4">
              <div class="w-50">
                <LabelComponent text="Canal" />
                <v-select
                  v-model="Text_Canal.selected"
                  :items="Text_Canal.answers"
                  item-text="label"
                  item-value="value"
                  atta
                  outlined
                  dense
                  no-data-text="No hay información"
                  append-icon="mdi-chevron-down"
                  @change="setSucursals()"
                ></v-select>
              </div>
              <div class="ml-7 w-50">
                <LabelComponent text="Sucursal" />
                <v-select
                  v-model="Text_Sucursal.selected"
                  :items="Text_Sucursal.answers"
                  item-text="label"
                  item-value="value"
                  atta
                  outlined
                  dense
                  :loading="loadingSucursals"
                  no-data-text="No hay información"
                  append-icon="mdi-chevron-down"
                  @change="sucursalesApprove"
                ></v-select>
              </div>
            </div>
          </v-form>
          <div class="w-100 d-flex justify-end mt-8">
            <v-form ref="authorize">
              <v-btn
                class="text-none"
                color="primary"
                rounded
                outlined
                width="160"
                v-on:click.native="close"
              >
                Cancelar
              </v-btn>
              <v-btn
                class="ml-4 text-none"
                color="primary"
                rounded
                width="210"
                @click="Aceptar"
                :loading="loadingstatus"
                :disabled="approveBtn"
              >
                Aprobar usuario
              </v-btn>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import LabelComponent from "@/components/Inputs/Label.vue";
import { Register } from "../.././Mixins/RegisterMixin";

export default {
  mixins: [Register],
  name: "UserAproveDialog",
  props: ["open", "userdata"],
  components: {
    LabelComponent,
  },
  data() {
    return {
      sucursalSelected: "",
      convenioSelected: "",
      Text_Canal: {
        label: "",
        answers: [],
        selected: this.convenioSelected,

        id: [],
        rules: [(v) => !!v || "Canal es requerido"],
      },
      Text_Sucursal: {
        label: "",
        answers: [],
        selected: this.sucursalSelected,
        disabled: true,
        rules: [(v) => !!v || "Sucursal es requerido"],
      },
      approveBtn: true,
    };
  },
  methods: {
    close() {
      this.approveBtn = true;
      this.Text_Canal.selected = "";
      this.Text_Sucursal.selected = "";
      this.$store.commit("ApproveModal");
    },

    sucursalesApprove() {
      if (this.$refs.authorize.validate()) {
        this.approveBtn = false;
      }
    },

    Aceptar() {
      var People = this.$store.getters.Personas;

      People.map((it) => {
        if (it.id_solicitud === this.userdata.id_solicitud) {
          it.estado = "ACE";
          it.estado_descripcion = "Aceptado";
        }
      });

      if (this.$refs.authorize.validate()) {
        const solicitation = {
          estado: "A",
          motivo: "usuario autorizado",
          canal: this.Text_Canal.selected,
          sucursal: this.Text_Sucursal.selected,
          solicitud: this.userdata.id_solicitud,
          aprobado_por: this.user.usuario,
        };
        this.$store
          .dispatch("ChangeUserStatus", solicitation)
          .then((res) => {
            this.$store.commit("setSnackbar", {
              active: true,
              text: res.lista_mensaje[0].mensaje,
              top: true,
              right: true,
              color: "success",
            });
          })
          .catch((message) => {
            this.$store.commit("setSnackbar", {
              active: true,
              text: message,
              top: true,
              right: true,
              color: "error",
            });
          })
          .finally(() => {
            this.close();
            this.approveBtn = true;
            this.$store.getters.Personas.filter((it) => it.estado === "PEN");
          });
      }
    },
  },

  computed: {
    loadingstatus() {
      return this.$store.getters.loadingstatus;
    },
    user() {
      return this.$store.getters.user;
    },
  },

  watch: {
    open() {
      if (this.open == true) {
        this.textarea = "";
      }
    },
    userdata(userdata) {
      this.Text_Canal.selected = userdata.canal;
      this.setSucursals();
      this.Text_Sucursal.selected = userdata.sucursal;
    },
  },

  mounted() {},
};
</script>

<style scoped>
@import "../../assets/CSS/ServiceStyle.css";
</style>
