<template>
  <div class="text-center">
    <v-dialog v-model="open" width="600px" persistent attach="#main-container">
      <v-card class="py-5">
        <v-card-text>
          <div class="d-flex justify-space-between align-center">
            <span class="bold h5">Rechazar usuario</span>
            <v-btn v-on:click.native="close" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div class="d-flex flex-column">
            <v-form ref="rejectuser">
              <span class="p mb-2 mt-4">Escriba el motivo del rechazo.</span>
              <v-textarea
                v-model="textA"
                rows="4"
                outlined
                auto-grow
                row-height="20"
              ></v-textarea>
            </v-form>
          </div>
          <div class="d-flex justify-space-between align-center">
            <Alert variant="error" class="w-100 mt-2">
              <template slot="content">
                <div>
                  <span class="p"
                    >Estás a un paso de rechazar al usuario
                    <span class="bold text-capitalize">
                      {{ userdata.nombre_largo }}, Rut
                      {{ userdata.usuario | rutFormat }}
                    </span>
                  </span>
                </div>
              </template>
            </Alert>
          </div>
          <div class="d-flex mt-4">
            <span class="p">¿Estas seguro que desear rechazar al usuario?</span>
          </div>
          <div class="w-100 d-flex justify-end mt-8">
            <v-btn
              class="text-none"
              color="primary"
              rounded
              outlined
              width="160"
              v-on:click.native="close"
            >
              Cancelar
            </v-btn>
            <v-btn
              class="ml-4 text-none"
              color="primary"
              rounded
              width="160"
              :loading="loadingstatus"
              :disabled="!textA"
              @click="Reject"
            >
              Rechazar usuario
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Alert from "@/components/Alert/Alert.vue";

export default {
  data: () => ({
    rules: [(v) => !!v || "Motivo es requerido"],
    dialog: true,
    textA: "",
  }),
  props: ["open", "userdata"],
  components: {
    Alert,
  },
  methods: {
    close() {
      this.textA = "";
      this.$store.commit("RechazarModal");
    },

    Reject() {
      var People = this.$store.getters.Personas;

      People.map((it) => {
        if (it.id_solicitud === this.userdata.id_solicitud) {
          it.estado = "REJ";
          it.estado_descripcion = "Rechazado";
        }
      });
      if (this.$refs.rejectuser.validate()) {
        const solicitation = {
          estado: "REC",
          motivo: this.textA,
          canal: this.userdata.canal,
          sucursal: this.userdata.sucursal || "",
          solicitud: this.userdata.id_solicitud,
          aprobado_por: this.user.usuario,
        };
        this.$store
          .dispatch("ChangeUserStatus", solicitation)
          .then((res) => {
            this.$store.commit("setSnackbar", {
              active: true,
              text: res.lista_mensaje[0].mensaje,
              top: true,
              right: true,
              color: "success",
            });
          })
          .catch((message) => {
            this.$store.commit("setSnackbar", {
              active: true,
              text: message,
              top: true,
              right: true,
              color: "error",
            });
          })
          .finally(() => {
            this.$store.commit("RechazarModal");
            this.$store.getters.Personas.filter((it) => it.estado === "PEN");
          });
      }
    },
  },
  watch: {
    open() {
      if (this.open == true) {
        this.textA = "";
      }
    },
    userdata: function (userdata) {
      this.loadingCanal = true;

      this.$store.dispatch("GetCanales", {
        convenio: userdata.convenio,
        canal: userdata.canal,
        sucursal: userdata.sucursal,
      });
    },
  },

  computed: {
    loadingstatus() {
      return this.$store.getters.loadingstatus;
    },
    user() {
      return this.$store.getters.user;
    },
    closeModal() {
      return !open;
    },
  },
};
</script>

<style scoped>
@import "../../assets/CSS/ServiceStyle.css";

h4 {
  z-index: 10;
}
#BackgroundSvgs {
  position: absolute;
  top: 0%;
  right: -10;
}
#ComputerSvg {
  z-index: 10;
}
</style>
